@font-face {
  font-family: Hattori_Hanzo;
  src: url("../public/font/Hattori_Hanzo.woff") format("woff"), url("../public/font/Hattori_Hanzo.ttf")  format("truetype");
}

@font-face {
  font-family: Hattori_Hanzo;
  font-style: italic;
  src: url("../public/font/Hattori_Hanzo_Italic.woff") format("woff"), url("../public/font/Hattori_Hanzo_Italic.ttf")  format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
