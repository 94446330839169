.App {
  text-align: center;
}

a {
  text-decoration: none;
  color: black;
}

.mv-logo {
  height: 120px;
  pointer-events: none;
}


.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  color: rgb(65, 2, 31);
  margin-top: 10px;
}

.App-header .title {
  margin-top: 7px;
}

.home {
  min-height: calc(100vmin - 320px);
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  
}

.page-not-found {
  min-height: calc(100vmin - 320px);
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
}

.centered {
  justify-content: center;
}

.App-footer {
  position: relative;
  bottom: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  font-weight: 200;
  color: rgb(65, 2, 31);
}

.App-content a {
  color: black;
  text-decoration: underline;
  text-decoration-style: dotted;
}

.App-content a:hover {
  text-decoration-style: solid;

}

.tagline {
  padding-bottom: 20px;
}

.tagline a {
  text-decoration: none;
  color: black;
}

@keyframes appear {
  from {
    top: 50px;
    color: white;
  }
  to {
    top: 0px;
    color: black;
  }

}

.thanks-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 400px;
  height: 150px;
  background: #fafafa;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 60px;
  box-shadow: 0px 0px 20px 0px #00000029;
  font-family: 'Hattori_Hanzo', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.thanks {
  position: relative;
  top: 0px;
  animation-name: appear;
  animation-duration: 1s;
}